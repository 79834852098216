import resource1 from "@/static/images/advertNew-resource1.png";
import resource2 from "@/static/images/advertNew-resource2.png";
import resource3 from "@/static/images/advertNew-resource3.png";
export default [
  {
    img: resource1,
    computer: "1 vCPU 2GB",
    storage: "50 GB",
    network: "1000 GB",
    price: "7.7",
  },
  {
    img: resource2,
    computer: "1 vCPU 2GB",
    storage: "50 GB",
    network: "2000 GB",
    price: "10.0",
  },
  {
    img: resource3,
    computer: "1 vCPU 2GB",
    storage: "50 GB",
    network: "2000 GB",
    price: "12.0",
  },
];
