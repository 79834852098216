import { useIntl } from "react-intl";
import Img from "@/components/Img";
import resourceList from "./data";
import "./style.less";
const Resource = ({ btn = true, data = resourceList, click }: any) => {
  const intl = useIntl();

  const handleJump = () => {
    if (click) {
      click();
    }
    window.open(global.__webInfo?.registerUrl);
    return false;
  };

  return (
    <div className="resource-list">
      {data.map((v: any, i: number) => {
        return (
          <div key={i} className={`item-li ${i === 0 ? "active" : ""}`}>
            <div>
              <Img
                src={v.img}
                alt={global.__webInfo?.platformName + " resource"}
              />
              <div className="price-mobile">
                <span>${v.price}</span>
              </div>
            </div>
            <div className="config">
              <div>
                <span>{v.computer}</span>
                <span>
                  {intl.formatMessage(
                    { id: "pages.advertNew.resource.Computer" },
                    { platformName: global.__webInfo?.platformName }
                  )}
                </span>
              </div>
              <div>
                <span>{v.storage}</span>
                <span>
                  {intl.formatMessage(
                    { id: "pages.advertNew.resource.Storage" },
                    { platformName: global.__webInfo?.platformName }
                  )}
                </span>
              </div>
              <div>
                <span>{v.network}</span>
                <span>
                  {intl.formatMessage(
                    { id: "pages.advertNew.resource.Network" },
                    { platformName: global.__webInfo?.platformName }
                  )}
                </span>
              </div>
            </div>
            <div className="price">
              <span>${v.price}</span>
              {btn ? (
                <a
                  className="u-btn advert-link"
                  // href={global.__webInfo?.registerUrl}
                  onClick={handleJump}
                  title="sign up"
                  target="_blank"
                  rel="noreferrer"
                >
                  {intl.formatMessage(
                    { id: "pages.advert.config.btn" },
                    { platformName: global.__webInfo?.platformName }
                  )}
                </a>
              ) : null}
            </div>
            {btn ? (
              <a
                className="u-btn advert-link"
                // href={global.__webInfo?.registerUrl}
                onClick={handleJump}
                title="sign up"
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage(
                  { id: "pages.advert.config.btn" },
                  { platformName: global.__webInfo?.platformName }
                )}
              </a>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default Resource;
